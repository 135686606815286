.spinner.input-group {
  .input-group-addon {
    .spin-up,
    .spin-down {
      height: 10px;
      width: 10px;
      overflow: hidden;
      display: block;
      text-align: center;
      color: #999;

      &:hover {
        color: #555;
      }

      .fa {
        margin-top: -8px;
        vertical-align: middle;
      }

      .glyphicon {
        font-size: 10px;
        top: -2px;
      }
    }

    a.spin-up,
    a.spin-down {
      text-decoration: none;
    }

    button.spin-up,
    button.spin-down {
      background: none;
      border: none;
      padding: 0;
    }
  }

  &.input-group-sm .input-group-addon {
    .spin-up,
    .spin-down {
      height: 8px;

      .fa {
        margin-top: -12px;
      }

      .glyphicon {
        font-size: 8px;
        top: -5px;
      }
    }
  }

  &.input-group-lg .input-group-addon {
    .spin-up,
    .spin-down {
      height: 12px;
      width: 12px;

      .fa {
        margin-top: -16px;
      }

      .glyphicon {
        font-size: 12px;
        top: -6px;
      }
    }
  }
}
